import React from 'react'
import { injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl'
import { Container, Row, Col } from '@bootstrap-styled/v4'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { slugify } from '../util/js/slugify'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import Section from '../components/UI/Section'
import { H1 } from '../components/Typography'
import PreviewCard from '../components/PreviewCard'
import PreviewCardAsLink from '../components/PreviewCard/PreviewCardAsLink'
import { months } from '../util/js/constants'
import BlogCategoryFilter from '../components/BlogCategoryFilter'
import BlogPagination from '../components/BlogPagination'
import breakpoints from '../util/style/breakpoints'

const TemplateWrapper = styled.div`
  padding-top: 6.938rem;

  ${breakpoints.md`
    padding-top: 12.5rem;
  `}

  ${breakpoints.xl`
    padding-top: 17rem;
  `}

  .filter-spacing {
    margin-bottom: 2.5rem;
  }
`
export const blog = graphql`
  query($skip: Int!, $limit: Int!) {
    posts: allSanityBlogPost(
      sort: { order: DESC, fields: publishedAt }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          _rawBlogTitle
          publishedAt
          blogCategory {
            category
          }
          imgHeader {
            _key
            _rawAsset
            asset {
              url
            }
          }
        }
      }
    }
  }
`

class BlogOverview extends React.PureComponent {
  render() {
    const { intl, pageContext, data } = this.props
    const { previousPagePath, nextPagePath, numberOfPages } = pageContext

    return (
      <>
        <Layout>
          <IntlContextConsumer>
            {({ language: currentLocale }) => (
              <>
                <SEO title={intl.formatMessage({ id: 'site_title' })} />
                <TemplateWrapper>
                  <Section>
                    <Container className="d-flex">
                      <H1>Blog</H1>
                    </Container>
                  </Section>
                  <Section>
                    <Container>
                      <Row className="mb-5">
                        <Col>
                          <BlogCategoryFilter />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        {data &&
                          data.posts.edges.map(({ node }) => {
                            const url =
                              node._rawBlogTitle.nl &&
                              `/${slugify(
                                node._rawBlogTitle.nl
                              )}-${node.id.substring(0, 6)}`

                            const title = node._rawBlogTitle[currentLocale]
                            const category =
                              node.blogCategory && node.blogCategory.category
                            const image =
                              node.imgHeader && node.imgHeader._rawAsset
                            const currentDate = new Date(node.publishedAt)
                            const formattedDate = `${currentDate.getDate()} ${intl.formatMessage(
                              {
                                id: months[currentDate.getMonth()],
                              }
                            )} ${currentDate.getFullYear()}`

                            return (
                              <Col
                                md={4}
                                key={node.id}
                                className="mb-sm-4 mb-md-5"
                              >
                                <PreviewCardAsLink link={url}>
                                  <PreviewCard
                                    header={title}
                                    subHeader={formattedDate}
                                    previewImage={image}
                                    label={category}
                                  />
                                </PreviewCardAsLink>
                              </Col>
                            )
                          })}
                      </Row>
                      <Row>
                        <Col>
                          <BlogPagination
                            numberOfPages={numberOfPages}
                            previousPagePath={previousPagePath}
                            nextPagePath={nextPagePath}
                            currentPath='/blog'
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Section>
                  <Footer />
                </TemplateWrapper>
              </>
            )}
          </IntlContextConsumer>
        </Layout>
      </>
    )
  }
}

export default injectIntl(BlogOverview)
